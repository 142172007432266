* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 0.97rem;
}

/* Define a custom font */

@font-face {
  font-family: MyWebFont;
  src: url("../fonts/sweetlybroken.ttf");
}

/* Container holding all contents */


/* The container holding dashboard and main contents area */


/* Erropage */
#errorpage {
  color: rgba(0, 106, 120);
  text-align: center;
  background-image: url("../images/page_not_found.png");
  background-color: rgba(0, 106, 120, 0.2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

/* style the main contents area */
.mainarea {
  position: relative;
  height: 93vh;
  background-color: rgb(230, 228, 228);
  overflow-y: hidden;
  overflow-x: hidden;
}
.contentarea {
  position: relative;
  height: 72vh;
  background-color: rgb(230, 228, 228);
  overflow-y: auto;
  overflow-x: hidden;
 
}
.freelancermainarea {
  position: relative;
  height: 94vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
 
}
/* Style the text box for project details */
div[role="textbox"] {
  height: 40vh;
  overflow-y: auto;
}

/* style the dashboard */
.dashboard {
  position: relative;
  background-color: rgb(0, 106, 120);
  height: 95vh;
  padding: 0;
}

/* Style the table contents */
table {
  table-layout: fixed;
  width: 100%;
}

td {
  text-align: justify !important;
}
.wallettable {
  border-bottom: 1px solid #006a78 !important;
}

.accordionheader button.accordion-button.collapsed::after {
  display: none !important;
}
.writercard{
  height: 35vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.accordionheader button.accordion-button::after {
  display: none !important;
}
th {
  color: rgb(0, 106, 120);
  text-align: justify !important;
  padding: 0%;
}

.transactiontable {
  background-color: white;
  margin: 10px;
}
/* image and icon displayed inside projects title */
.project_image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.freelancer_image {
  width: 10vh;
  height: 10vh;
  border-radius: 50%;
  margin-right: 2vh;
}

.project_icon {
  color: rgb(196, 193, 193);
}

/* create a custom scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  background-color: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(1, 77, 87, 0.6);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 77, 87);
}

/* Style the dash */

#dash a {
  color: #0c2335;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}

#dash {
  background-color: #cedbf5;
  height: 17vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  margin-bottom: 5px;
}
#dash:hover {
  background-color: white;
}
.dash_image {
  margin-top: 1vh !important;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.dash_icon {
  font-size: 2.5em;
  color: #607d8b;
}

/* style the dashboard links and icons */
.navigationcolumn {
  height: 75vh;
  padding-bottom: 3vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.icon {
  color: white;
  font-size: 1.9em;
  padding-right: 5px;
  padding-bottom: 5px;
}


nav .navanchor:hover .icon {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
  color: green;
}

nav .navanchor {
  text-decoration: none;
  position: relative;
  color: white;
  font-size: 0.9em;
  font-weight: bold;
  letter-spacing:1px;
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 25px;
}

nav .navanchordrop{
   padding: 10px 10px 10px 60px !important;
}

nav .navanchor:hover {
  background-color: rgb(230, 228, 228);
  color: green;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 5px;
}

nav .navanchor:focus {
  background-color: beige;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 5px;
}

nav .navanchor.active {
  color: rgb(226, 219, 219);
  background-color: rgb(1, 34, 34);
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 5px;
}

nav .navanchor.active .icon {
  transform: scale(1.2);
  color: #e2dbdb;
}

.ai-prompt{
  bottom: "5%",
}
nav .navanchor:hover .itemscount {
  color: green;
}

nav .navanchor.active .itemscount {
  color: #35f035;
}

/* Style for holding the items counts */
.itemscount {
  position:absolute;
  right: 2vw;
  font-weight: bold;
  color: #35f035;
}

/* Style the pills on sidebars */

.btn-custom {
  color: white;
  background-color: green;
}

/* style the tabs  */

.nav-item .nav-link.active {
  background-color: rgba(0, 106, 120, 0.7) !important;
  color: white !important;
  border-color: rgba(0, 106, 120, 0.7) !important;
}

li.nav-item .nav-link {
  color: rgb(0, 106, 120);
  background-color: white !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* style the projects display */

.projectinfo {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.accordion-item {
  margin-bottom: 5px !important;
  border: 2px solid rgb(204, 202, 202) !important;
  border-radius: 5px !important;
}

.itemscontainer {
  background-color: white;
  border-radius: 5px;
  padding: 10px 20px 20px 20px;
  height: calc(93vh - 9vh);
  overflow-y: auto;
  overflow-x: hidden;
  
}

.itemscontainerwithtabs {
  background-color: white;
  border-radius: 5px;
  height: calc(90vh - 7vh - 25px);
  margin-bottom: 2vh;
  overflow-x: hidden;
  overflow-y: auto;
}
#accordionhead {
  background-image: #014d57 !important;
}
/* style the accordion */

button.accordion-button[aria-expanded="true"] {
  background-color: #ddf8ef !important;
  box-shadow: none !important;
}
button.accordion-button:hover {
  background-color: #ddf8ef !important;
}
button.accordion-button[aria-expanded="false"] {
  box-shadow: none !important;
}

.accordion-item .accordion-header {
  outline: none !important;
  padding: 0 !important;
}

.accordion-item .accordion-header.accordionhead {
  outline: none !important;
  padding: 0 !important;
  background-color: #014d57 !important;
}

.availableProjects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.paginationcontainer {
  cursor: pointer;
  
}

.paginationitem {
  width: 4vh;
  height: 4vh;
  border-radius: 50%;
  text-align: center;
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  cursor: pointer;
  margin-right:1px;
}
.papertype {
  background-color: #006a78;
  color: white;
  width: fit-content;
  padding: 4px;
  padding-top: 8px;
}
.projecttrusted {
  background-color: #006a78;
  color: white;
  width: fit-content;
  padding: 4px;
  padding-top: 8px;
}
.projectescrow {
  background-color: green;
  color: white;
  width: fit-content;
  padding: 4px;
  padding-top: 8px;
}
.projecttitle {
  color: #006a78;
}
.projectintext {
  color: #607d8b;
}
/* used to style the icons inside of a project such as bid icon, date icon etc */
.projecticon {
  color: #006a78;
  margin-right: 5px;
  vertical-align: middle;
}
/* Style for page titles */
.pagetitle {
  background-color: white;
  box-shadow: 5px 5px 3px #607d8b;
  border-radius: 5px;
  color: #093b03;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 1vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  position: relative;
}
.freelancerpagetitle {
  background-color: #b8e4bf;
  box-shadow: 5px 5px 3px #607d8b;
  color: #093b03;
  height: 5vh;
  display: flex;
}
.ck-editor__editable {
  height: 150px !important;
}
.ck-editor__editable .postprojecteditor {
  height: 300px !important;
}

ol li {
  margin-bottom: 5px;
}
ul li {
  margin-bottom: 5px;
}

.whatsapp {
  position: absolute;
  bottom: 1vh;
  right: 3vw;
  height: 4vh;
  width: auto;
  z-index: 100;
}

.pagenavbutton {
  font-size: larger;
  font-weight: 700;
  position: absolute;
  left: 2vw;
}

.pagenavbutton:hover {
  cursor: pointer;
}

.title {
  font-size: 1.2em;
  margin-top: 1vh;
  font-family: "EssayHostFontBold";
  vertical-align: middle;
}
.freelancerform{
margin-top: 6px;
  vertical-align: middle;
}
/* Style the loader */

.loader {
  height: 100vh;
}

.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}
.contents{
  height: 85vh;
  padding-bottom: 3vh;
  overflow-x: hidden;
  overflow-y: auto;
}
/* style the offcanvas column and header*/

.cardcontainer{
  height: 95vh;
  overflow-x: hidden;
  overflow-y: auto;

}

.offcanvascolumn {
  background-color: #014d57 !important;
  padding: 0% !important;
}

.myoffcanvas {
  width: 50vw !important;
}

/* style the deposit amount */
.bidamount {
  color: rgb(0, 106, 120);
}

.ckeditor {
  height: 40vh !important;
  overflow-y: auto;
}
.navcontainer{
  height: 5vh !important;
}
/* Style the employer and writers dashboard cards*/

.mycard {
  min-height: calc(87vh / 3);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1vh;
  transition: all 1s ease-out;
}
.mycard:hover {
  box-shadow: 5px 5px 5px #014d57;
}
.cardvalues {
  color: #014d57;
}
.cardicon {
  height: 80px;
  width: 80px;
  margin-right: 3vw;
}
h6.card-header {
  background-color: #13daa8;
  color: white;
}

.card_text {
  text-align: center;
  margin-bottom: 3vh;
  color: #014d57;
}

h5 {
  color: #014d57 !important;
  font-family: EssayHostFontBold;
}

h6 {
  color: #014d57;
  font-family: EssayHostFontBold;
}
/* style images */

.nav_image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.nav_icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.project_image {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: right 3px;
}

.project_no_image {
  font-size: 1.7em;
  color: rgb(189, 186, 186);
  margin: right 3px;
}
.freelancer_no_image {
  font-size: 4em;
  color: rgb(214, 211, 211);
  margin-right: 2vh;
}
.chat_no_image {
  font-size: 3em;
  color: rgb(189, 186, 186);
  margin: right 3px;
  float: left;
}

.selectinput:focus{
  border: none;
  outline: inherit;
}

.filterContainer{
  position: relative;

}
.filter{
  position: absolute;
  left: 2vh;
  cursor: pointer;
  bottom: 2vh;
  pointer-events: none;
}

.selectinput{
    -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; 
  height:fit-content;
  vertical-align: middle;
  background-color: inherit;
  text-align: left;
  padding: 5px;
  width: 5vw;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.profile_image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.profile_image_placeholder {
  font-size: 5em;
  color: #eeeaea;
  margin: 1vh;
}

.info_profile_image_placeholder {
  font-size: 5em;
  color: #eeeaea;
  margin: 1vh;
}
.info_profile_image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/* Style the sidebar */

.sidebarbutton {
  font-size: 2.5em;
  display: none;
}

.sidebarbuttonanimated {
  animation-name: sidebarrotate;
  animation-duration: 1s;
}

.smallsidebaricon {
  color: #006a78;
}
.smallsidebartext {
  font-size: xx-small;
  color: #006a78;
}
nav .smallsidebarnavanchor {
  width: 20%;
  height: 7vh;

  text-decoration: none;
}
.smallsidebarbutton {
  width: 20%;
}
nav .smallsidebarnavanchor:hover .icon {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
  color: green;
}

@keyframes heartbeat {
  
  0% {
    transform: scale(1);
    background-color: white;
    color: white;
  }

  50% {
    background-color: #b8e4bf;
  }
  75% {
    transform: scale(1);
    background-color: white;
  }

  100% {
    transform: scale(1);
    background-color: #b8e4bf;
  }
}

nav .smallsidebarnavanchor:hover {
  background-color: rgb(230, 228, 228);
}

nav .smallsidebarnavanchor:hover .smallsidebartext {
  color: #093b03 !important;
}
nav .smallsidebarnavanchor:hover .smallsidebaricon {
  color: #093b03;
}
nav .smallsidebarnavanchor:focus {
  background-color: beige;
}

nav .smallsidebarnavanchor.active {
  background-color: #b8e4bf;
  animation: heartbeat 1s ease-in-out;
}
nav .smallsidebarnavanchor.active .smallsidebartext {
  color: #093b03 !important;
}

nav .smallsidebarnavanchor.active .smallsidebaricon {
  transform: scale(1.2);
  color: #093b03 !important;
}

.projectsidebar {
  display: none;
}
@keyframes sidebarrotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg);
  }
}

.chatpagetitle {
  background-color: #b8e4bf;
  box-shadow: 5px 5px 3px #607d8b;
  color: #093b03;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vh;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* button:hover {
  box-shadow: 0 0 10px rgb(95, 151, 95);
} */

.sectiondiv {
  height: 5vh;
  width: 5vh;
  border-radius: 50%;
  padding-top: 4px;
  color: white;
  background-color: #b8dcec;
  cursor: pointer;
}
.sectiondiv2 {
  height: 5vh;
  width: 5vh;
  border-radius: 50%;
  padding-top: 4px;
  color: black;
  background-color: white;
  border: green 4px solid;
  cursor: pointer;
}

.sectiondiv:hover {
  box-shadow: 0 0 10px #008000;
}

.emptyfolder {
  height: 15vh;
  width: auto;
}

.emptyfolderContainer {
  width: 20vw;
  height: 30vh;
  background-color: none;
  padding: 10px;
}
.textAligner {
  text-align: right !important;
}

.projectUser {
  display: flex;
  height: 5vh;
  padding: 5px 10px;
  background-color: #cedbf5;
  display: flex;
  align-items: center;
  align-items: center;
  color: black;
}
.ratingstar {
  font-size: 1.25em;
}

.chat_no_image {
  font-size: 3em;
  color: rgb(189, 186, 186);
}

.chatcolumn {
  overflow-y: auto;
  overflow-x: hidden;
}

.chat_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.mpesaimage{
  height: 5vh;
   width: 8vw;
   margin: 0;
   padding: 0 ;
}

  .finance-icon{
    font-size: 3em;
    margin-bottom: 2vh;
    margin-top: 2vh;
    color: green
  }

/* style all contents when the screen is less than 990px */
@media screen and (max-width: 768px) {
  .ai-prompt{
    bottom:"8vh";
    left:"10%"
  }
 
  .itemscontainerwithtabs{
    height: 75vh;
  }
  .itemscontainer{
    padding-bottom: 8vh;
  }
  .contents{
    height: 75vh;
    padding-bottom: 2vh;
  }
  .mpesaimage{ 
  height: 4vh;
   width: auto;
   margin-left: 2vw;
   padding: 0 ;
}
  .chat_no_image {
  font-size: 2em !important;
  color: rgb(189, 186, 186);
}

.chatcolumn {
  overflow-y: auto;
  overflow-x: hidden;
}
.whatsapp{
  bottom: 8vh;
}
.chat_image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
  .boldrating {
    font-size: x-small;
  }
  
  .textAligner {
    text-align: left !important;
  }
  .paginationitem {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
    cursor: pointer;
    margin-right: 2px;
  }
  .cardvalues {
    font-size: 1.5em;
    vertical-align: text-bottom;
  }
  .projectsidebar {
    display: block;
  }
  .transactiontable {
    margin: 5px;
  }


  .smallsidebar {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 7vh;
    display: flex;
    justify-content: space-around;
    /* box-shadow: 0 -4px 2px rgba(0, 0, 0, 0.3); */
    align-items: center;
    background-color: white !important;
  }

  .cardcontainter {
    padding: 5px;
  }
  .mycard {
    margin: 1vw;
    min-height: calc(80vh / 3);
    background-color: white;
    border: #b1c2ca 1px solid;
    padding: 1vh;
  }
  .bottomprofile {
    margin-top: 4vh;
  }

  button.accordion-button {
    padding: 5px 5px !important;
  }

  .accordion-item .accordion-header {
    outline: none !important;
    height: fit-content !important;
  }

  .dashboard {
    display: none;
  }

  * {
    font-size: x-small;
  }
  .sidebarbutton {
    display: block;
  }
  .maincontainer {
    height: 100vh;
    padding-bottom: 10vh;
    overflow: hidden;
  }
  th,
  td {
    padding: 0;
    font-size: small;
    text-align: center;
  }

  * {
    font-size: small;
  }

  ::-webkit-scrollbar {
    width: 1px;
  }

  .cardicon {
    position: relative;
    height: 50px;
    width: 50px;
  }

  .pagenavbutton {
    font-size: medium;
    font-weight: 700;
    position: absolute;
    left: 4vw;
  }
  .mainarea {
    height: 94vh;
  }
  .emptyfolder {
    height: 10vh;
    width: auto;
  }
  .emptyfolderContainer {
    width: 44vw;
    height: 25vh;
    background-color: none;
    padding: 10px;
  }
  .contentarea{
    height: 70vh;
  }
 
}

/* color inline items */
.inlineitem {
  color: green;
  font-weight: bold;
}

input[id="profile_image"] {
  display: none;
}
.employer_no_image{
  font-size: 2em;
  color:grey
}



/* style writer profile display */
/* .mainprofilecard {
  box-shadow: 5px 5px 15px #014d57;
} */
.profilecard {
  min-height: 9vh;
  overflow-y: auto;
  text-align: center;
  margin-bottom: 1vh;
  margin-top: 1vh;
  padding-top: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: calc(90vh / 3);
  background-color: white;
  /* box-shadow: 5px 5px 15px #014d57; */
}

.profilecardimage {
  width: 60px;
  height: 60px;
  margin: 2vh;
}

.profiletitle {
  text-align: center;
  color: green;
  border-bottom: #04283a 1px solid;
}

.walletcard {
  margin: 1.5vh;
  overflow-y: auto;
  text-align: center;
  background-color: white;
}
.stopdisplay {
  display: block;
}
.chatnavbutton {
  font-size: medium;
  font-weight: 700;
  position: absolute;
  left: 30vw;
  cursor: pointer;
}




@media screen and (max-width: 468px) {

  .mpesaimage{ 
    height: 4vh;
   width: auto;
   margin-left: 2vw;
   padding: 0 ;
}

  .stopdisplay {
    display: none;
  }
  button.accordion-button {
    padding: 5px 5px !important;
  }

  .accordion-item .accordion-header {
    outline: none !important;
    padding: 0 !important;
    height: fit-content !important;
  }

  th,
  td {
    padding: 0;
    font-size: x-small;
  }

  * {
    font-size: small;
  }

  .maincontainer {
    overflow-y: hidden;
    padding-bottom: 10vh;
  }
  .signup {
    padding-bottom: 4vh !important;
  }

  .emptyfolder {
    font-size: small;
  }
   .chatpagetitle {
    background-color: #b8e4bf;
    box-shadow: 5px 5px 3px #607d8b;
    color: #093b03;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vh;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.receiverStyle {
  background-color: #e5e5ea;
  color: black;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  margin: 5px;
  width: fit-content;
  max-width: 60%;
  align-self: flex-end;
  list-style-type: none;
  flex-direction: column;
  position: relative;
  left: 5%;
}

.senderStyle {
  background-color: #006a78;
  color: white;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  margin: 5px;
  width: fit-content;
  max-width: 60%;
  align-self: flex-start;
  list-style-type: none;
  flex-direction: column;
  position: relative;
  left: 30%;
}

.chatnavlink {
  padding: 10px !important;
  border-bottom: 2px solid #065279;
}

nav .chatnavlink:hover .itemscount {
  color: green;
}
nav .chatnavlink:hover {
  background-color: white;
  color: green;
}
nav .chatnavlink:focus {
  background-color: beige;
}

nav .chatnavlink.active {
  color: white;
  background-color: #006a78;
  width: 100%;
}


.nochaticon {
  font-size: 7em;
  color: #006a78;
  margin: right 3px;
}

.noconversation {
  font-size: 1.5em;
  color: #006a78;
  margin: top 100px;
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatOffCanvas {
  background-color: #cedbf5 !important;
  width: 94vw !important;
  top: 16vh !important;
  bottom: 10vh !important;
  left: 3vw !important;
  right: 3vw !important;
}
.chatOffCanvasHeader {
  padding: 3vw !important;
  padding-left: 33vw !important;
  border-bottom: #006a78 1px solid !important;
  background-color: #cedbf5 !important;
}
.chatOffCanvasBody {
  background-color: #cedbf5 !important;
  padding: 0% !important;
}

.logoutbutton {
  position: relative;
  bottom: 3vh !important;
}

.star {
  color: #006a78;
  cursor: pointer;
}
.starselected {
  color: gold;
  cursor: pointer;
}

.rating {
  display: flex;
  position: relative;
  margin-top: -1vh;
}

.searchicon {
  position: absolute;
  top: 20%;
  right: 80%;
  transform: translateY(-50%);
  cursor: pointer;
}

.searchbar {
  padding-right: 30px;
}

.messagesContainer {
  overflow-y: auto;
  overflow-x: hidden;
  height: 60vh;
}

.writer_image {
  height: 6vh;
  width: 6vh;
  border-radius: 50%;
}
.writer_no_image {
  height: 6vh;
  width: 6vh;
  border-radius: 50%;
}

.review_image_placeholder {
  height: 4vh;
  width: 4vh;
}
.review_image {
  height: 3vh;
  width: 3vh;
  margin-right: 2vw;
}
