#faqsmain{
    overflow-x: hidden;
    margin: 0; 
}

#faqscontainer{
    width: 100%;
    min-height: 100vh;
    padding-top: 10vh;
    padding-left: 1vw;
    padding-right: 1vw;
    background-color: rgb(230, 228, 228);;
}


.faqscontent{
    padding: 2vh;
}
.faqscontent ol li h6{
    color: rgb(0, 106, 120); 
}
.faqscontent ol li{
    line-height: 2em;
}
.faqscontent p b{
    color: rgb(0, 106, 120);
}