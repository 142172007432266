/*style the navigation */
/* Define a custom font */

@font-face {
  font-family: MyWebFont;
  src: url("../fonts/Cinerama.otf");
}

.navicon {
  color: aliceblue;
  font-size: 1.75em;
  padding-right: 0px;
}

#navbrand {
  font-family: MyWebFont;
  font-size: 1.6em;
}
.bell {
  color: white;
  font-size: 1.5em;
}

/* Change the background color of the navbar */
.color-nav {
  background-color: #006a78; 
}
.faqs-nav {
  background-color: rgb(0, 106, 120);
}
.transparent-nav {
  background: transparent;
  transition: background-color 0.5s ease-in;
}
.transparent-nav a.nav-link.active {
  background: none !important;
  color: green !important;
  text-decoration: underline;
}
.transparent-nav a.dropdown-toggle.nav-link {
  color: green !important;
}
.transparent-nav a.dropdown-toggle.nav-link:hover {
  color: #0087ca !important;
}

.opaque-nav {
  background-color: #d5f6f7;
  box-shadow: 0 5px 5px grey;
  transition: background-color 0.5s ease-in;
}
.opaque-nav a.nav-link.active {
  background: none !important;
  color: green !important;
  text-decoration: underline;
}
.opaque-nav a.dropdown-toggle.nav-link {
  color: green !important;
}

.cash {
  font-size: 1.3em;
  color: white;
}

.logo {
  height: 42px;
  width: 42px;
  position: relative;
  top: -5px;
  padding: 0px;
}

.dropdown_icon {
  color: "#212529";
}
.nav-item a.nav-link {
  color: white;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #00ca2c;
}

.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #00ca2c;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

a.nav-link.hover-underline-animation-faqs:hover {
  color: white !important;
}

.hover-underline-animation-faqs {
  display: inline-block;
  position: relative;
  color: white;
}

.hover-underline-animation-faqs::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation-faqs:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

a.dropdownlink.active:active {
  background-color: rgb(221, 229, 231) !important;
}
a.dropdown-item:active {
  background-color: rgb(221, 229, 231) !important;
}
/* style the navigation while the screen is below 768px  */
@media screen and (max-width: 768px) {
  #responsive-navbar-nav {
    background-color: rgb(0, 106, 120);
    padding: 10vw;
  }

  div#responsive-navbar-nav.navbar-collapse.collapse.show a.nav-link {
    color: white !important;
  }
  .transparent-nav a.dropdown-toggle.nav-link {
    color: white !important;
  }
  .opaque-nav a.dropdown-toggle.nav-link {
    color: white !important;
  }
  .nav-item .nav-link {
    border: none !important;
  }

  .nav-item .nav-link .active {
    border: none !important;
    background: transparent;
  }

  button.btn-close {
    background-color: #607d8b !important;
  }
}

button.navbar-toggler {
  background-color: green;
}
