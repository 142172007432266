.adminmainarea {
  position: relative;
  background-color: rgb(230, 228, 228);
  height: 90vh;
  margin: 0 !important;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  border-top: 1px solid #b6c0c5;
}
.custom-card {
  margin-top: -5vh;
  box-shadow: 5px 5px 5px rgb(23, 58, 46);
}

div.card-header.admin {
  background-color: rgb(23, 58, 46);
  color: white;
}

.custom-row {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.page-header-row {
  margin: 0% !important;
}

.page-header {
  background-color: rgb(23, 58, 46);

  padding: 20px;
  margin: 0;

  height: 15vh;
}

.admin_dash_icon {
  width: 4vw;
  height: 7vh;
  object-fit: contain;
}

/* style all contents when the screen is less than 768px */
@media screen and (max-width: 768px) {
  .custom-card {
    margin: 10px;
  }
  .admin_dash_icon {
    width: 10vw;
    height: 17vh;
    object-fit: contain;
  }
}
