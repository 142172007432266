@font-face {
  font-family: EssayHostFontBold;
  src: url("../fonts/Axiforma-ExtraBold.ttf");
}
@font-face {
  font-family: EssayHostFontRegular;
  src: url("../fonts/Axiforma-Regular.ttf");
}
@font-face {
  font-family: EssayHostFontMedium;
  src: url("../fonts/Axiforma-Medium.ttf");
}
@font-face {
  font-family: EssayHostFontLight;
  src: url("../fonts/Axiforma-Light.ttf");
}
@font-face {
  font-family: EssayHostFontThin;
  src: url("../fonts/Axiforma-Thin.ttf");
}
@font-face {
  font-family: EssayHostFontHeavy;
  src: url("../fonts/Axiforma-Heavy.ttf");
}

* {
  margin: 0;
  font-family: "EssayHostFontRegular", "EssayHostFontThin", "EssayHostFontLight",
    "EssayHostFontBold", "EssayHostFontHeavy", "EssayHostFontMedium", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Segoe UI", Tahoma;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}
