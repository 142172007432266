@font-face {
  font-family: MyWebFont;
  src: url("../fonts/Cinerama.otf");
}

@font-face {
  font-family: EssayHostFontBold;
  src: url("../fonts/Axiforma-ExtraBold.ttf");
}
@font-face {
  font-family: EssayHostFontRegular;
  src: url("../fonts/Axiforma-Regular.ttf");
}
@font-face {
  font-family: EssayHostFontMedium;
  src: url("../fonts/Axiforma-Medium.ttf");
}

@font-face {
  font-family: EssayHostFontThin;
  src: url("../fonts/Axiforma-Thin.ttf");
}

@font-face {
  font-family: EssayHostFontHeavy;
  src: url("../fonts/Axiforma-Heavy.ttf");
}

#homepage{
  overflow: auto;
   -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
}

/* Hide scrollbars in WebKit browsers */
#homepage::-webkit-scrollbar {
  display: none;
}


.main_image_container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #F0F8FF;
}




/* elements inside the main image  */
.top-left {
  animation: top-left;
  width: 70vw;
  position: absolute;
  top: 15vh;
  left: 5vw;
  border: #015208 4px solid;
  border-radius: 2vh;
  display: none;
}

.bottom-center {
  position: absolute;
  bottom: 8px;
  left: 40vw;
}

.carouseltext{
  line-height: 1.5;
}

.headingmain{
  font-weight: bold;
  color: rgb(0, 106, 120);
  font-size: 1.8em;
 
}
.subheading {
  color:green;
  font-family: "EssayHostFontBold";
}
.subheading1 {
  margin-bottom: 3vh;
  color: rgb(0, 106, 120);
  font-family: "EssayHostFontBold";
}

.subheading2 {
  text-align: center;
  color: rgb(0, 106, 120);
}
.subheading3 {
  color: green;
  text-align: center;
}

.hometext {
  font-size: 1.3em;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: EssayHostFontRegular;
  color: grey;
}
.hometext1 {
  font-size: 1.3em;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: EssayHostFontRegular;
  color: white;
}


.smallnoshow{
  display: block;
}
.homeheading{
  font-weight: bolder;
  font-size: 4em;
  font-family:EssayHostFontHeavy;
  line-height: 1.2;
  color: rgb(25, 135, 84);
}
.homeheading .highlight {
  color: rgb(0, 106, 120);
  /* You can inherit other properties if needed */
  font-size: inherit;
  font-weight: inherit;
  font-family:EssayHostFontHeavy;
}
.homep{
  line-height: 2;
  font-family: EssayHostFontRegular;
  color: rgb(24, 135, 84);
}
.topcard{
  min-height: 30vh;
  background-color: rgb(0, 106, 120) !important;
  display:flex;
  align-items: center;
  padding: 2vh;
  margin: 2vh;
 
}
.homecard{
  min-height: 53vh !important;
  background-color: rgb(209, 252, 252) !important;
  color: white;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  padding: 2vh;
}
.carouselcard{
   min-height: 50vh !important;
  background-color: rgb(0, 106, 120)  !important;
  color: white ;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  padding: 2vh;
  border-radius: 5vh;
 
}
.homecard:hover{
  cursor: pointer;
  transform: scaleY(1.05); /* Apply the scaleY transformation on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add box-shadow on hover */
}

.whyessayhost{
  background-color: rgb(250, 252, 252);
}

.homecard.border-white {
  border: none;
}
.topcard.border-white{
  border: none;
}
.bottomcard{
  min-height: 85vh !important;
  padding: 3vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add box-shadow on hover */
  transition: transform 0.3s ease-in-out;

}
.bottomcard:hover{
  cursor: pointer;
  transform: scaleY(1.05); /* Apply the scaleY transformation on hover */

}
.bottomcard.border-white{
  border: none;
}
.countcard{
  height: 20vh !important;
  background-color: #0269a5 !important;
}
.carouselhead{
  min-height: 50vh !important;
}
#howitworks {
  
  min-height: max-content;
 
}
.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#features {
  min-height: fit-content;
  background-color: #F0F8FF;
 
}
.homeiconsimage {
  width: 15vh;
  height: 15vh;
  display: flex;
  background-color: white;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2vh 0vh;
}
.featuresimage {
  width: 20vw;
  height: 30vh;
  border-radius: 50%;
}

.featurescont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 20vh;
  margin: 5vh;
}

.homeicons {
  width: 70px;
  height: 70px;
}
.homesvg2 {
  position: relative;
  left: 30%;
}
.loginregister {
  display: none;
}
/* Style the footer */
.footer {
  width: 100%;
  height: 30vh;
  background-color: #036674;
}
.footer_bottom {
  background-color: #014d57;
  padding: 1vh;
  bottom: 0px;
}
.footericon {
  color: white;
  width: 40px;
  height: 40px;
  transition: transform 1s ease-in-out;
}
.footericon:hover {
  transform: scale(1.2);
}
.footer_row {
  margin-right: 10vw;
}
.footerlink {
  color: white;
  text-decoration: none;
}
.footerlink:hover {
  color: white !important;
}
.essayhostlogo {
  width: 60px;
  height: 60px;
}

.howitworkstext {
  line-height: 1.5em;
  font-family: EssayHostFontRegular;
  color: #000;
}
/* Hover underline for footer */

.hover-underline-animation-footer {
  display: inline-block;
  position: relative;
  color: white;
}

.hover-underline-animation-footer::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation-footer:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
/* Style content of landing page */

.mobilecover {
  display: none;
  overflow: hidden;
}

.footertext {
  font-family: MyWebFont;
  color: white;
}
.essayhostmail {
  transition: transform 0.5s ease-in-out;
}
.essayhostmail:hover {
  transform: scale(1.1);
}

.footerbutton {
  color: #014d57;
  width: fit-content;
  background-color: white;
  padding: 7px;
  border-radius: 3px;
}

.footerbutton:hover {
  box-shadow: 5px 5px 5px #014d57;
}
.number {
  height: 30px;
  width: 30px;
  background-color: #014d57;
  border-radius: 50%;
  color: white;
  position: relative;
  left: 45%;
  text-align: center;
  margin-bottom: 2vh;
  cursor: pointer;
}
.textcover {
  display: none;
}

.whatsappsupport{
  position: fixed;
  bottom: 4vh;
  right: 3vw;
  height: 3vh;
  width: auto;
  z-index: 100;
}

.platform{
  background-image: url("../images/EssayHostInDesktop.png");
  background-size:contain; /* Ensure the background image covers the entire element */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  background-position: center; /* Center the background image */
  width: 100%; /* Set the width of the element */
  height: 40vh; /* Set the height of the element */
  display: flex; /* Enable flexbox layout */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  padding: 20px; /* Add padding inside the element */
  border-radius: 10px; /* Add rounded corners */
}
.platform1{
  background-image: url("../images/EssayHostInPhone2.png");
  background-size:contain; /* Ensure the background image covers the entire element */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  background-position: center; /* Center the background image */
  width: 100%; /* Set the width of the element */
  height: 40vh; /* Set the height of the element */
  display: flex; /* Enable flexbox layout */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  padding: 20px; /* Add padding inside the element */
  border-radius: 10px; /* Add rounded corners */
}
.countcard{
  background-color: rgba(1, 82, 8, 0.4);
}
.whatsapp {
    position: fixed;
    bottom: 4vh;
    right: 2vw;
    height: 40px;
    width: 40px;
    z-index: 100;
  }
.smallcarouselhead{
  display: none !important;
}
/* Style the elements while below a certain breakpoint */
@media screen and (max-width: 768px) {
  #features{
    padding: 2vh !important;
    margin-top: 2vh !important;
  }
  .bottomcard{
    height: 20vh !important;
    margin-bottom: 2vh;
  }
  .testimonials{
    padding: 2vh !important;
    margin-bottom: 2vh;
  }
  #howitworks{
    padding: 2vh !important;
  }
  .whyessayhost{
    padding: 2vh !important;
    display: flex;
    justify-content: center !important;
  }
  .homecard{
    max-height: fit-content !important;
    padding: 0;
    margin-bottom: 2vh;
  }
  .topcard{
    margin: 0;
    padding: 0;
  }
  .carouselhead{
  display: none !important;
}
.smallcarouselhead{
  display: block !important;
}
  .smallnoshow{
    display: none !important;
  }
.headingmain{
  margin-top: 0;
}
 
  .bottomcard{
    height: max-content !important;
  }
  .whatsapp {
    position: fixed;
    bottom: 8vh;
    right: 3vw;
    height: 30px;
    width: 30px;
    z-index: 100;
  }
  .whatsappsupport{
  position: fixed;
  bottom: 10vh;
  right: 3vw;
  height: 25px;
  width: 70px;
  z-index: 100;
}
  .loginregister {
    display: block;
    position: relative;
    top: 45vh;
  }
  .number {
    left: 47%;
    cursor: pointer;
  }
  .cardcontainer{
    padding-bottom: 10vh ;
  }
  .accountbutton {
    display: block;
    width: 70%;
    position: absolute;
    top: 80%;
    left: 15%;
    padding: 7px;
    border-radius: 3px;
  }
  .accountbutton2 {
    display: block;
    color: white;
    width: 70%;
    position: absolute;
    top: 90%;
    left: 15%;
    padding: 7px;
    border-radius: 3px;
  }
  .accountbutton:hover {
    box-shadow: 5px 5px 5px #014d57;
  }
  .footerbutton {
    color: #014d57;
    width: fit-content;
    background-color: white;
    padding: 5px;
  }
  video {
    display: none;
  }
  .mobilecover {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 65vh;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
  }
  .textcover {
    display: block;
    position: absolute;
    top: 15%;
    left: 5%;
    padding: 1vw;
  }
  .mobiletextcoverh1 {
    font-family: EssayHostFontHeavy !important;
    font-size: 4em !important;
    line-height: 7vh !important;
    font-weight: 900 !important;
    color: #015208;
    animation: slideFromLeft 1s ease-in-out;
  }
  .mobiletextcoverh3 {
    font-family: EssayHostFontBold !important;
    font-size: 2.5em !important;
    line-height: 6vh !important;
    font-weight: 700 !important;
    margin-top: 3vh;
    text-align: left !important;
    animation: slideFromRight 1s ease-in-out;
    color: #014d57;
  }
  .mobiletextcoverp {
    font-family: EssayHostFontRegular !important;
    font-size: 1.3em !important;
    line-height: 4vh !important;
    margin-top: 3vh;
    animation: slideFromBottom 1s ease-in-out;
    color: #022333;
  }
  .footericon {
    color: white;
    width: 20px;
    height: 20px;
    transition: transform 1s ease-in-out;
  }
  .footericon:hover {
    transform: scale(1.2);
  }
  .main_image_container {
    position: relative;
    height: 100vh;
    background-image: url("../images/mobilecover.svg");
    background-size: cover;
  }
  .homesvg {
    width: 40%;
    height: auto;
  }
  .homesvg2 {
    display: none;
  }
  .top-left {
    display: none;
  }
  .featuresimage {
    width: 50vw;
    height: auto;
  }
  .featurescont {
    height: auto;
  }
  .homeicons {
    margin-bottom: 2vh;
  }
 
  #howitworks {
    min-height: 110vh;
  }

  #features {
    min-height: 100vh;
  }
  .footer {
    height: fit-content;
  }
  .hometext {
    font-size: 1em;
    text-align: justify;
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated-col {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
}

.animated-col.in-view {
  opacity: 1;
  animation: fadeIn 0.5s forwards;
}

.radiating-circles {
  position: relative;
  height: 60vh;
  width: 60vh;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radiating-circles::before, .radiating-circles::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500%;
  height: 500%;
  background: radial-gradient(circle, rgba(209, 252, 252, 0.8) 0%, rgba(190, 243, 243, 0.8) 10%, transparent 10%);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: radiate 4s linear infinite;
  z-index: 1; /* Place the circles in the background */
}

.radiating-circles::after {
  animation-delay: 2s;
}


@keyframes radiate {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0;
  }
}
