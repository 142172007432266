.form {
  display: flex;
  flex-direction: column;
}

#loginform {
  height: 80vh;
}
/* container holding all form contents */

.form_main {
  min-height: 100vh;
  background-color: whitesmoke;
}

.formside {
  background-image: url("../images/freelancework.jpg");
  background-position: center;
  background-size: cover;
  min-height: inherit;
  box-shadow: 10px 10px 10px rgb(184, 220, 243);
}

.formcontent {
  background-color: #006a78;
  padding-top: 3vh;
  box-shadow: 0px 10px 10px rgb(184, 220, 243);
}

.passwordResetForm {
  width: 40vw;
}

.error {
   color: #f84a6d;
    font-weight:lighter;
    font-size: small;
    text-align: center;
    vertical-align: middle;
    position: relative;
    margin-bottom: -4px;
    margin-top: -10px;
}

.input_error {
  border-color: #f84a6d;
}

input {
  
    
    border: none;
    border-radius: 5px;
  padding-left: 5px;
  margin-bottom: 1vh;
  height: 35px;
  border: 1px solid rgb(171, 197, 171);
  border-radius: 5px;
}
input[type=file]{
  border: none;
}
select {
  padding-left: 5px;
  margin-bottom: 1vh;
  height: 35px;
  border: 1px solid rgb(171, 197, 171);
  border-radius: 5px;
}
.react-tel-input{
  width: inherit !important;
}

textarea {
  border: 2px solid rgb(168, 190, 168);
  border-radius: 3px;
  margin-bottom: 1vh;
}

/* Style the input fields */

input[type="file"] {
  cursor: pointer;
}

input[type="radio"] {
  accent-color: green;
}

input[type="checkbox"]:focus {
  outline: 0;
}

input[type="checkbox"] {
  vertical-align: middle;
  position: relative;
  top: 2px;
}

input[type="radio"] {
  vertical-align: middle;
  position: relative;
  top: 4px;
}

input.auth {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom: 2px solid green;
  background-color: white;
}

input:focus {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom: aqua;
  outline: 2px solid rgb(70, 97, 29);
}

button:disabled {
  opacity: 0.35;
}

#logo {
  width: 45px;
  height: 45px;
  margin-right: 1vw;
}

p {
  display: block;
  font-weight: 400;
}

/* Display some form elements side by side */
.displayrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0;
  margin-top: 0;
}

/* style the authentication form */
.mainformrow {
  min-height: 100vh;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: auto;
}

#register {
  margin: 5vh;
  position: relative;
  min-height: max-content;
  display: flex;
  color: white;
}

.formtitle {
  color: #006a78;
  margin-top: 1vh;
  margin-bottom: 2vh;
  font-size: 1.5em;
  font-family: EssayHostFontBold;
}

.link {
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

label {
  color: white;
}

.postlabel {
  font-weight: bold;
  color: #006a78;
  margin-bottom: -10px;
}

.radio:focus {
  outline: none;
}

a.link {
  color: white;
  font-weight: 600;
}
a.link:hover {
  color: white !important;
}
a {
  text-decoration: none;
}

textarea {
  padding: 10px;
}

.logodisplay {
  margin-bottom: 2vh;
}
.PasswordResetformcontent {
  background-color: #6d9da3;
  padding-top: 3vh;
  box-shadow: 10px 10px 10px rgb(184, 220, 243);
}

.react-tel-input{
  display: flex !important;
}

/* .react-tel-input .form-control { 
  display: block !important;
  border: none !important;
  border-radius: 5px !important;
  padding:10px 10px !important;
  margin-bottom: 1vh !important;
  height: 35px !important;
  flex: 1 !important;
} 

@media screen and (min-width: 1366) {
  .react-tel-input .form-control { 
  width: 400 !important;
}
} */

@media screen and (max-width: 768px) {
  .passwordResetForm {
    width: 70vw;
  }
  .PasswordResetformcontent {
    background-color: #6d9da3;
    padding-top: 3vh;
    margin-right: 3vw;
    box-shadow: none;
  }
  #logo {
    width: 30px;
    height: 30px;
    margin-right: 3vw;
  }
  #logosmall {
    width: 30px;
    height: 30px;
    margin-right: 3vw;
  }
  #register {
    min-height: 100vh;
    padding: 4vw;
  }

  #loginform {
    height: 50vh !important;
  }
  .formtitle {
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    color: white;
    font-size: medium;
  }
  .signupimage {
    display: none;
  }
  .form_main {
    background-image: none;
    background-color: #006a78 !important;
  }
  .formcontent {
    min-height: min-content;
    width: 100%;
    padding-bottom: 50px;
    border-radius: 5px;
    background-color:  rgb(0, 106, 120);
    box-shadow: none;
    overflow-y: auto;
  }

  .mainformrow {
    padding-right: 3vw;
    min-height: 100vh;
    overflow-y: auto;
  }

  a.link {
    color: white;
  }
}
input[id="profile_pic"] {
  display: none;
}
/* Display icons inside inputs */

.inputicon {
  color: grey;
  font-size: 1.1em;
  cursor: pointer;
}
